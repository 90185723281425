import Nav from './components/nav'
import Fot from './components/footer';
import './style.css';
import docras from './assets/Doctors-pana.webp';
import missionvission from './assets/aboutus/missionvission.webp';
import missionvissiondark from './assets/missionvissiondark.webp';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';


export default function Aboutus() {
  const { t } = useTranslation();
  const [lightModeScreen, setLightModeScreen] = useState(true);

  useEffect(() => {
    lightModeScreenManager();
    const intervalId = setInterval(lightModeScreenManager, 100);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function lightModeScreenManager() {
    let savedValueForMode = localStorage.getItem('Mode');
    if (savedValueForMode == null) {
      setLightModeScreen(true);
      localStorage.setItem('Mode', true);
    } else {
      setLightModeScreen(savedValueForMode === 'true');
    }
  }

  return (
    <div className='all-about-us-page' style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
      <Nav />
      <>
        <div className="aboutus-container" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
          <div className="aboutus-container-left" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
            <div className="aboutus-container-header" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
              <h1>{t("aboutusmain1")}</h1>
            </div>
            <div className="aboutus-container-parag">
              <p style={{ color: lightModeScreen ? 'black' : 'white' }}> {t("aboutusmain1desc1")} <br /> {t("aboutusmain1desc2")} <br /> {t("aboutusmain1desc3")} <br /> {t("aboutusmain1desc4")} </p>
            </div>
            <div className="aboutus-container-parag">
              <div className="aboutus-container-right">
            <div className="abourus-container-button">
              <a href='/services' className="arrow-button">{t("aboutusalink")}</a>
            </div>
          </div>
            </div>
          </div>
        </div>

        <div className="aboutus-container-middle-page-down-bottom" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
          <img src={lightModeScreen ? missionvission : missionvissiondark} />
        </div>
        <div className="aboutus-container-middle-down-page" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>

          <div className="aboutus-container-middle-down-page-content" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>


            <div className="aboutus-container-middle-page-down-right">
              <div className="aboutus-container-middle-page-down-left-header">
                <h1>{t("aboutusmain3")}</h1>
              </div>
              <div className="aboutus-container-middle-page-down-left-parag">
                <p style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("aboutusmain3desc")}</p>
              </div>
            </div>


            <div className="aboutus-container-middle-page-down-left">
              <div className="aboutus-container-middle-page-down-left-header">
                <h1>{t("aboutusmain4")}</h1>
              </div>
              <div className="aboutus-container-middle-page-down-left-parag">
                <p style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("aboutusmain4desc")}</p>
              </div>
            </div>
          </div>

        </div>


        <div class="map-container" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
          <div class="map-info" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
            <h1>Resonance Imaging & Diagnostic Center</h1>
            <p style={{ color: lightModeScreen ? 'black' : 'white' }}>Villa 1204 Al Wasl Road, Umm Suqeim 2<br />
              Dubai, United Arab Emirates.</p>
            <p><a href="tel:+97142295550" style={{ color: lightModeScreen ? 'black' : 'white' }}>+971 4 2295550</a> | <a href="tel:+971549957801" style={{ color: lightModeScreen ? 'black' : 'white' }}>+971 549957801</a></p>
            <p style={{ color: lightModeScreen ? 'black' : 'white' }}>For You, We Never Stop.</p>
            <h2>{t("aboutusmapopening")}</h2>
            <ul>
              <li style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("aboutusmaptext1")}</li>
              <li style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("aboutusmaptext2")}</li>
            </ul>
          </div>
          <div class="map-embed">
            <iframe id="map-frame" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7223.503821664324!2d55.207168!3d25.144077!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69960d2d32d7%3A0x899f8432ad0a8d8d!2sResonance%20Imaging%20and%20Diagnostic%20Center!5e0!3m2!1sen!2sae!4v1711967764968!5m2!1sen!2sae" frameborder="0" allowfullscreen aria-hidden="false" tabindex="0"></iframe>
          </div>
        </div>
        <Fot />
      </>
    </div>


  );

};