import Nav from './components/nav'
import Fot from './components/footer';
import './style.css';
import doctor from './assets/doctoradawdwa.webp';
import salam from './assets/salamhears.webp';
import heart from './assets/heartawdwae.webp';
import villa from './assets/villa.webp';
import docras from './assets/Doctors-pana.webp';
import chedoc from './assets/Checklist-pana.webp';
import enjo from './assets/ENJOYABLE-MRI-EXPERIENCE.webp';
import mri from './assets/mRI.webp';
import ct from './assets/CT.webp';
import degm from './assets/Digital-Mammography.webp';
import degx from './assets/Digital-Xray.webp';
import degp from './assets/dental-imaging.webp';
import ult from './assets/ULTRASOUND-RIDC.webp';
import rev from './assets/Online Review-pana.webp';
import in1 from './assets/Insurance/in1.webp';
import in2 from './assets/Insurance/in2.webp';
import in3 from './assets/Insurance/in3.webp';
import in4 from './assets/Insurance/in4.webp';
import in5 from './assets/Insurance/in5.webp';
import in6 from './assets/Insurance/in6.webp';
import in7 from './assets/Insurance/in7.webp';
import in8 from './assets/Insurance/in8.webp';
import in9 from './assets/Insurance/in9.webp';
import in10 from './assets/Insurance/in10.webp';
import in11 from './assets/Insurance/in11.webp';
import in12 from './assets/Insurance/in12.webp';
import in13 from './assets/Insurance/in13.webp';
import in14 from './assets/Insurance/in14.webp';
import in15 from './assets/Insurance/in15.webp';
import in16 from './assets/Insurance/in16.webp';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';


export default function Home() {
  const {t} = useTranslation();
  const [comment, setComment] = useState(1);
  const [count, setCount] = useState(0);
  const [animationTriggered, setAnimationTriggered] = useState(false);
  const [lightModeScreen, setLightModeScreen] = useState(true);
    const refCardContainer = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && !animationTriggered) {
                setAnimationTriggered(true);
                const cards = entry.target.querySelectorAll('.card');
                cards.forEach((card) => {
                    card.classList.add('animate-visible');
                });
            }
        });
    }, {
        threshold: 0.5 // Trigger animation when 50% of the element is visible
    });

    // Start observing when components are mounted
    observer.observe(refCardContainer.current);

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
}, [animationTriggered]);

  useEffect(() => {
    const timerId = setTimeout(nextComment, 8000);

    return () => clearTimeout(timerId);
  }, [comment]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const { classList } = entry.target;
            if (classList.contains('hiddenfromright')) {
              entry.target.classList.add('show', 'animate-slide-in-right');
            } else if (classList.contains('hiddenfromleft')) {
              entry.target.classList.add('show', 'animate-slide-in-left');
            } else {
              entry.target.classList.add('show');
            }
            // Stop observing once animation is triggered
            observer.unobserve(entry.target);
          } else {
            entry.target.classList.remove('show', 'animate-slide-in-right', 'animate-slide-in-left');
          }
        });
      },
      {
        // Add the 'once' option
        once: true
      }
    );

    const hiddenElements = document.querySelectorAll('.hidden, .hiddenfromright, .hiddenfromleft');
    hiddenElements.forEach((el) => observer.observe(el));

    // Clean up observer when component unmounts
    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  useEffect(() => {
    lightModeScreenManager();
    const intervalId = setInterval(lightModeScreenManager, 100);
    return () => {
      clearInterval(intervalId);
    };
}, []);


  function handleIncrement() {
    if (count === 4) {
      setCount(1);
    } else {
      setCount(count + 1);
    }
  };

  function nextComment() {
    if (comment == 10) {
      setComment(1);
    } else {
      setComment(comment + 1);
    }
  }

  function nextPrevious() {
    if (comment == 1) {
      setComment(10);
    } else {
      setComment(comment - 1);
    }
  }




  function viewComment(commentNumber) {
    let reviewContent;
    switch (commentNumber) {
      case 1:
        reviewContent = (
          <div className="review">
            <div className="review3 active">
              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homecommentname1")}</h3>
              <p className="rating">★★★★★</p>
              <div className="reviews-text">
                <p className="comment" style={{ color: lightModeScreen ? '#202020' : '#e8e8e8' }}>{t("homecommentdesc1")}</p>

                <p className="author" style={{ color: lightModeScreen ? '#535353' : '#cfcfcf' }}>- {t("homecommentname1")}</p>
              </div>
            </div>
          </div>
        );
        break;
      case 2:
        reviewContent = (
          <div className="review">
            <div className="review3 active">

              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homecommentname2")}</h3>
              <p className="rating">★★★★★</p>
              <div className="reviews-text">
                <p className="comment" style={{ color: lightModeScreen ? '#202020' : '#e8e8e8' }}>{t("homecommentdesc2")}</p>
                <p className="author" style={{ color: lightModeScreen ? '#535353' : '#cfcfcf' }}>- {t("homecommentname2")}</p>

              </div>
            </div>
          </div>
        );
        break;
      case 3:
        reviewContent = (

          <div className="review">
            <div className="review4 active">

              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homecommentname3")}</h3>
              <p className="rating">★★★★★</p>
              <div className="reviews-text">
                <p className="comment" style={{ color: lightModeScreen ? '#202020' : '#e8e8e8' }}>{t("homecommentdesc3")}</p>
                <p className="author" style={{ color: lightModeScreen ? '#535353' : '#cfcfcf' }}>- {t("homecommentname3")}</p>

              </div>
            </div>
          </div>
        );
        break;
      case 4:
        reviewContent = (

          <div className="review">
            <div className="review5 active">

              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homecommentname4")}</h3>
              <p className="rating">★★★★★</p>
              <div className="reviews-text">
                <p className="comment" style={{ color: lightModeScreen ? '#202020' : '#e8e8e8' }}>{t("homecommentdesc4")}</p>
                <p className="author" style={{ color: lightModeScreen ? '#535353' : '#cfcfcf' }}>- {t("homecommentname4")}</p>

              </div>
            </div>
          </div>
        )
        break;
      case 5:
        reviewContent = (

          <div className="review">
            <div className="review6 active">

              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homecommentname5")}</h3>
              <p className="rating">★★★★★</p>
              <div className="reviews-text">
                <p className="comment" style={{ color: lightModeScreen ? '#202020' : '#e8e8e8' }}>{t("homecommentdesc5")}</p>
                <p className="author" style={{ color: lightModeScreen ? '#535353' : '#cfcfcf' }}>- {t("homecommentname5")}</p>

              </div>
            </div>
          </div>
        );
        break;
      case 6:
        reviewContent = (

          <div className="review">
            <div className="review7 active">

              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homecommentname6")}</h3>
              <p className="rating">★★★★★</p>
              <div className="reviews-text">
                <p className="comment" style={{ color: lightModeScreen ? '#202020' : '#e8e8e8' }}>{t("homecommentdesc6")}</p>
                <p className="author" style={{ color: lightModeScreen ? '#535353' : '#cfcfcf' }}>- {t("homecommentname6")}</p>


              </div>
            </div>
          </div>
        );
        break;
      case 7:
        reviewContent = (

          <div className="review">
            <div className="review8 active">

              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homecommentname7")}</h3>
              <p className="rating">★★★★★</p>
              <div className="reviews-text">
                <p className="comment" style={{ color: lightModeScreen ? '#202020' : '#e8e8e8' }}>{t("homecommentdesc7")}</p>
                <p className="author" style={{ color: lightModeScreen ? '#535353' : '#cfcfcf' }}>- {t("homecommentname7")}</p>

              </div>
            </div>
          </div>
        );
        break;
      case 8:
        reviewContent = (

          <div className="review">
            <div className="review9 active">

              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homecommentname8")}</h3>
              <p className="rating">★★★★★</p>
              <div className="reviews-text">
                <p className="comment" style={{ color: lightModeScreen ? '#202020' : '#e8e8e8' }}>{t("homecommentdesc8")}</p>
                <p className="author" style={{ color: lightModeScreen ? '#535353' : '#cfcfcf' }}>- {t("homecommentname8")}</p>

              </div>
            </div>
          </div>
        );
        break;
      case 9:
        reviewContent = (

          <div className="review">
            <div className="review10 active">

              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homecommentname9")}</h3>
              <p className="rating">★★★★★</p>
              <div className="reviews-text">
                <p className="comment" style={{ color: lightModeScreen ? '#202020' : '#e8e8e8' }}>{t("homecommentdesc9")}</p>
                <p className="author" style={{ color: lightModeScreen ? '#535353' : '#cfcfcf' }}>- {t("homecommentname9")}</p>

              </div>
            </div>
          </div>
        );
        break;
      case 10:
        reviewContent = (

          <div className="review">
            <div className="review11 active">

              <h3 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homecommentname10")}</h3>
              <p className="rating">★★★★★</p>
              <div className="reviews-text">
                <p className="comment" style={{ color: lightModeScreen ? '#202020' : '#e8e8e8' }}>{t("homecommentdesc10")}</p>
                <p className="author" style={{ color: lightModeScreen ? '#535353' : '#cfcfcf' }}>- {t("homecommentname10")}</p>

              </div>


            </div>
          </div>
        );
        break;
      default:
        reviewContent = <h1>Error</h1>;
        break;
    };
    return reviewContent;

  }

  function lightModeScreenManager() {
    let savedValueForMode = localStorage.getItem('Mode');
    if (savedValueForMode == null) {
      setLightModeScreen(true);
      localStorage.setItem('Mode', true);
    } else {
      setLightModeScreen(savedValueForMode === 'true');
    }
  }
  function openMap(event) {
    event.preventDefault();
    window.open(event.target.href, '_blank');
}


  return (
    <div className='homepage' style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
      <Nav />
      <>
        <div className="nav-bar">
          <div className="nav-bar-left">
            <div className="header-img-nav-bar">
              <div className="header-nav-bar">
                <h1 className='header-text' style={{ color: lightModeScreen ? '#333333' : 'white' }}>For <span className="highlight">You</span><br />We never <span className="highlight2">Stop</span></h1>
              </div>
              <div className="middle-nav-bar">
                <p style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homewelcomedesc")}</p>
                <a href='/services' className="services-btn">{t("homeservicesbutton")}</a>
              </div>
            </div>
          </div>
          <div className="footer-nav-bar">
            <img src={docras} alt="Logo" className="logo" loading="lazy" width="1600" height="900" />
          </div>
        </div>

        <div className="hidden">
          <div className="why-us">
            <h2 style={{ color: lightModeScreen ? 'black' : 'white' }}>Why<span className="highlight"> us </span>?</h2>
            <div ref={refCardContainer} className="card-container">
              <div className="card">
                <img src={doctor} loading="lazy" alt="A doctor image." />
                <h3>{t("hometopdoctors")}</h3>
                <p>{t("hometopdoctorsdesc")}</p>
                <a href='/ourteam' >{t("hometopdoctorsbutton")}</a>
              </div>
              <div className="card">
                <img src={salam} className="salamhear" loading="lazy" alt="A Heart image." />
                <h3>{t("homepatientcentered")}</h3>
                <p>{t("homepatientcentereddesc")}</p>
                <a href='/services'>{t("homepatientcenteredbutton")}</a>
              </div>
              <div className="card">
                <img src={heart} loading="lazy" alt="A Heart image." />
                <h3>{t("homequality")}</h3>
                <p>{t("homequalitydesc")}</p>
                <a href='https://g.page/r/CY2NCq0yhJ-JEBM/review' target='_blank'>{t("homequalitybutton")}</a>
              </div>
            </div>
          </div>
        </div>

        <div className="container" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
          <div className='image-container-container'>
            <div className="hiddenfromleft" style={{ width: "100%", height: "100%" }}>
              <img src={chedoc} alt="Image" className="container-img" loading="lazy" />
            </div>
          </div>
          <div className="hiddenfromright">
            <div className="content">

              <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}><span className="highlight">Best</span> Healthcare, <span className="highlight">Latest</span> Medical Technology</h1>
              <p className="content-par-parag" style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homebesthealthcaredesc")}</p>
              <p className="content-par"><span className="highlight">{t("homebesthealthcaremain")}</span></p>
              <ul>
                <li style={{ color: lightModeScreen ? '#5d5d5d' : '#dcdedc' }}><span className="check-icon">&#x2713;</span> {t("homebesthealthcarestate1")}</li>
                <li style={{ color: lightModeScreen ? '#5d5d5d' : '#dcdedc' }}><span className="check-icon">&#x2713;</span> {t("homebesthealthcarestate2")}</li>
                <li style={{ color: lightModeScreen ? '#5d5d5d' : '#dcdedc' }}><span className="check-icon">&#x2713;</span> {t("homebesthealthcarestate3")}</li>
                <li style={{ color: lightModeScreen ? '#5d5d5d' : '#dcdedc' }}><span className="check-icon">&#x2713;</span> {t("homebesthealthcarestate4")}</li>
                <li style={{ color: lightModeScreen ? '#5d5d5d' : '#dcdedc' }}><span className="check-icon">&#x2713;</span> {t("homebesthealthcarestate5")}</li>
              </ul>
              <div className="btn-container">
                <a href="/aboutus" className="services-btn">{t("homebesthealthcarebutton")}</a>
              </div>
            </div>
          </div>
        </div>

        <div className="services-section-cards">
          <div className='services-section-cards-header'>
            <div className='hidden'>
              <h2 className='h2-animated-text' style={{ color: lightModeScreen ? 'black' : 'white' }}>Our <span className='highlight'> services </span></h2>
            </div>
          </div>
          <div className="services-section-card-container">
            <div className="hiddenfromleft">
              <div className="services-section-card">
                <img src={enjo} loading="lazy" alt="A MRI image." width="1600" height="900" />
                <h3>{t("homeenjoyablemri")}</h3>
                <div className='para-serv'>
                  <p>{t("homeenjoyablemridesc")}</p>
                </div>
                <div className='services-section-card-btn'>
                  <a href='/enjoymriexperience' >{t("homeenjoyablemributton")}</a>
                </div>
              </div>
            </div>
            <div className="hiddenfromleft">
              <div className="services-section-card">
                <img src={mri} className="salamhear" loading="lazy" alt="A MRI image." width="1600" height="900" />
                <h3>{t("homemri")}</h3>
                <div className='para-serv'>
                  <p>{t("homemridesc")}</p>
                </div>
                <div className='services-section-card-btn'>
                  <a href='/mri'>{t("homemributton")}</a>
                </div>
              </div>
            </div>
            <div className="hiddenfromright">
              <div className="services-section-card">
                <img src={ct} loading="lazy" alt="A CT-Scan image." width="1600" height="900" />
                <h3>{t("homectscan")}</h3>
                <div className='para-serv'>
                  <p>{t("homectscandesc")}</p>
                </div>
                <div className='services-section-card-btn'>
                  <a href='/ctscan'>{t("homectscanbutton")}</a>
                </div>
              </div>
            </div>

            <div className="hiddenfromright">
              <div className="services-section-card">
                <img src={degm} loading="lazy" alt="A Digital Mammogram image." width="1600" height="900" />
                <h3>{t("homedigitalmammogram")}</h3>
                <div className='para-serv'>
                  <p>{t("homedigitalmammogramdesc")}</p>
                </div>
                <div className='services-section-card-btn'>
                  <a href='/digitalmammogram'>{t("homedigitalmammogrambutton")}</a>
                </div>
              </div>
            </div>
            <div className="hiddenfromleft">
              <div className="services-section-card">
                <img src={degx} loading="lazy" alt="A Digital X-Ray image." width="1600" height="900" />
                <h3>{t("homedigitalxray")}</h3>
                <div className='para-serv'>
                  <p>{t("homedigitalxraydesc")}</p>
                </div>
                <div className='services-section-card-btn'>
                  <a href='/digitalxray'>{t("homedigitalxraybutton")}</a>
                </div>
              </div>
            </div>
            <div className="hidden">
              <div className="services-section-card">
                <img src={degp} loading="lazy" alt="A Dental Imaging image." width="1600" height="900" />
                <h3>{t("homedentalimaging")}</h3>
                <div className='para-serv'>
                  <p>{t("homedentalimagingdesc")}</p>
                </div>
                <div className='services-section-card-btn'>
                  <a href='/dentalimagining'>{t("homedentalimagingbutton")}</a>
                </div>
              </div>
            </div>
            <div className="hiddenfromright">
              <div className="services-section-card">
                <img src={ult} loading="lazy" alt="A Ultra Sound image." width="1600" height="900" />
                <h3>{t("homeultrasound")}</h3>
                <div className='para-serv'>
                  <p>{t("homeultrasounddesc")}</p>
                </div>
                <div className='services-section-card-btn'>
                  <a href='/ultrasound'>{t("homeultrasoundbutton")}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-container">
          <button class="arrow-button-review left-arrow" onClick={nextPrevious} style={{ color: lightModeScreen ? 'black' : 'white' }}>←</button>
          <div className="left-div">
            <div className="hiddenfromleft">
              <div className="review-container">
                {viewComment(comment)}

              </div>
            </div>
            <button class="arrow-button-review" onClick={nextComment} style={{ color: lightModeScreen ? 'black' : 'white' }}>→</button>
          </div>
          

          <div className="right-image">
            <div className="hiddenfromright">
              <img src={rev} alt=" Image" className="container-img" loading="lazy" />
            </div>
           
          </div>
          
        </div>


        <section className='sponser-section'>
  <div className='sponser-main-header'>
    <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>The <span className='highlight'>Acceptable cards and insurances</span></h1>
    <p></p>
  </div>
  <div className='sponser-middle'>
    <img src={in1} loading="lazy" alt="First comment of the client" />
    <img src={in2} loading="lazy" alt="Second comment of the client" />
    <img src={in3} loading="lazy" alt="Third comment of the client" />
    <img src={in4} loading="lazy" alt="Fourth comment of the client" />
    <img src={in5} loading="lazy" alt="Fifth comment of the client" />
    <img src={in6} loading="lazy" alt="Sixth comment of the client" />
    <img src={in7} loading="lazy" alt="Seventh comment of the client" />
    <img src={in8} loading="lazy" alt="Eighth comment of the client" />
    <img src={in9} loading="lazy" alt="Ninth comment of the client" />
    <img src={in10} loading="lazy" alt="Tenth comment of the client" />
    <img src={in11} loading="lazy" alt="Eleventh comment of the client" />
    <img src={in12} loading="lazy" alt="Twelfth comment of the client" />
    <img src={in13} loading="lazy" alt="Thirteenth comment of the client" />
    <img src={in14} loading="lazy" alt="Fourteenth comment of the client" />
    <img src={in15} loading="lazy" alt="Fifteenth comment of the client" />
    <img src={in16} loading="lazy" alt="Sixteenth comment of the client" />
  </div>
</section>


        <hr />
        <div class="map-container" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
          <div class="map-info" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
            <h1>Resonance Imaging & Diagnostic Center</h1>
            <p style={{ color: lightModeScreen ? 'black' : 'white' }}>Villa 1204 Al Wasl Road, Umm Suqeim 2<br />
              Dubai, United Arab Emirates.</p>
            <p><a href="tel:+97142295550" style={{ color: lightModeScreen ? 'black' : 'white' }}>+971 4 2295550</a> | <a href="tel:+971549957801" style={{ color: lightModeScreen ? 'black' : 'white' }}>+971 549957801</a></p>
            <p style={{ color: lightModeScreen ? 'black' : 'white' }}>For You, We Never Stop.</p>
            <h2>{t("homemapopening")}</h2>
            <ul>
              <li style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homemaptext1")}</li>
              <li style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homemaptext2")}</li>
            </ul>
          </div>
          <div class="map-embed" onclick="openMap()">
            <iframe id="map-frame" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7223.503821664324!2d55.207168!3d25.144077!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69960d2d32d7%3A0x899f8432ad0a8d8d!2sResonance%20Imaging%20and%20Diagnostic%20Center!5e0!3m2!1sen!2sae!4v1711967764968!5m2!1sen!2sae" frameborder="0" allowfullscreen aria-hidden="false" tabindex="0"></iframe>
          </div>
        </div>



        <Fot />
      </>
    </div >


  );

};